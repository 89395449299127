<script setup>
    import { definePageMeta, usePageEnter } from '#imports';
    import { PreloaderComplete, UserReady } from '@fanatics/resn-api/instant-rips';
    import { onMounted } from 'vue';

    import MainScene from '~/components/gl/instant-rips/MainScene.vue';
    import PageWrapper from '~/components/PageWrapper.vue';
    import { useWindowMessage } from '~/composables/useWindowMessage';
    import { GlobalAssets } from '~/providers/GlobalAssets';
    import { useFanatics } from '~/providers/instant-rips/FanaticsProvider';

    const { sendMessage } = useWindowMessage();
    const fanatics = useFanatics();

    definePageMeta({
        layout: 'instant-rips',
    });

    onMounted(() => {
        sendMessage(UserReady);
    });

    usePageEnter(() => {
        sendMessage(PreloaderComplete);

        fanatics.ready.value = true;
    });
</script>

<template>
    <PageWrapper>
        <GlobalAssets>
            <MainScene />
        </GlobalAssets>
    </PageWrapper>
</template>

<style lang="scss" scoped>
    @import '@/styles/shared.scss';

    .page {
        @include fill;
        @include flexCenter;
        pointer-events: none;
    }
</style>
